import {
  asset1,
  asset2,
  asset3,
  asset4,
  asset5,
  asset6,
  asset7,
  asset8,
  asset9,
  asset10,
  asset11,
  asset12,
  asset13,
  asset14,
  asset15,
  nikeLogo,
  nikeLogoOutline,
} from './assets'

const assets = [
  { src: asset1, x: 217.7688, y: 139.074 },
  { src: asset2, x: 0, y: 17.8412, itemId: 106 },
  { src: asset3, x: 9.6176, y: 26.2236, itemId: 101 },
  { src: asset4, x: 273.0448, y: 5.9888 },
  { src: asset5, x: 430.916, y: 22.2096, itemId: 105 },
  { src: asset6, x: 273.0444, y: 5.27, itemId: 106 },
  { src: asset7, x: 469.2076, y: 242.8704, itemId: 103 },
  { src: asset8, x: 167.01, y: 1.6312, itemId: 106 },
  { src: asset9, x: 185.0112, y: 9.4956, itemId: 101 },
  { src: asset10, x: 201.3248, y: 1.5276, itemId: 102 },
  { src: asset11, x: 201.3248, y: 1.5276, globalComposition: 'multiply' },
  { src: asset12, x: 181.9464, y: 0.7192 },
  { src: asset13, x: 430.3032, y: 18.042, itemId: 105 },
  { src: asset14, x: 104.286, y: 756.7788 },
  { src: asset15, x: 151.0896, y: 757.3356, itemId: 111 },
  {
    src: nikeLogoOutline,
    x: 503.7124,
    y: 442.4516,
    itemId: 108,
    defaultColor: '#222222',
  },
  { src: nikeLogo, x: 507.7124, y: 446.4516, itemId: 107 },
  { itemId: 109, x: 621.2076, y: 296.3704, angle: 0 },
]

const data = {
  assets: assets,
}

export const swoosh = {
  assets: [
    {
      src: nikeLogoOutline,
      x: 0,
      y: 0,
      itemId: 108,
      defaultColor: '#222222',
    },
    { src: nikeLogo, x: 4, y: 4, itemId: 107 },
  ],
}

export default data
