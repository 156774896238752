import {
  ApolloClient,
  createHttpLink,
  ApolloLink,
  InMemoryCache,
  from,
} from '@apollo/client'
import { onError } from '@apollo/client/link/error'

const configUrl = process.env.REACT_APP_HTTP_BASEURL || 'stage.nikebuilder.com'
const host = window.location.host

let origin = ''
if (host.indexOf(configUrl) > -1) {
  origin = window.location.origin
} else {
  origin = 'https://stage.nikebuilder.com'
}

export const baseURL = origin + '/'

const httpLink = createHttpLink({
  uri: `${baseURL}graphql`,
})

const authMiddleware = new ApolloLink((operation, forward) => {
  // add the authorization to the headers
  let token = sessionStorage.getItem('token') || ''

  operation.setContext(({ headers = {} }) => ({
    headers: {
      ...headers,
      authorization: token,
    },
  }))

  return forward(operation).map((response) => {
    return response
  })
})

const logoutLink = onError(({ graphQLErrors }) => {
  if (graphQLErrors && graphQLErrors[0]) {
    const error: any = graphQLErrors[0]
    if (error.code === 'UNAUTHENTICATED') {
      const href = window.location.origin

      sessionStorage.removeItem('user')
      sessionStorage.removeItem('token')
      sessionStorage.removeItem('filters')

      window.location.href = href
    }
  }
})

export const client = new ApolloClient({
  link: from([authMiddleware, logoutLink, httpLink]),
  cache: new InMemoryCache(),
  credentials: 'same-origin',
})
