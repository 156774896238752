import React, { ButtonHTMLAttributes, forwardRef } from 'react'
import classes from './button.module.scss'

interface Props extends ButtonHTMLAttributes<HTMLButtonElement> {
  variant?: 'default' | 'primary' | 'outline' | 'primaryOutline'
  size?: 'medium' | 'large'
  inProgress?: boolean
  children: any
}

const Button = forwardRef<HTMLButtonElement, Props>((props: Props, ref) => {
  const {
    variant = 'default',
    size = 'medium',
    inProgress = false,
    children,
    ...rest
  } = props

  return (
    <button
      className={`${classes.button} ${classes[size]} ${classes[variant]}`}
      ref={ref}
      {...rest}>
      {children}
      {inProgress && <div className={classes.progress}></div>}
    </button>
  )
})

export default Button
