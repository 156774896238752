import React, { useState, useEffect } from 'react'
import { Outlet } from 'react-router-dom'
import { useMediaQuery } from 'react-responsive'
import ReactGA from 'react-ga4'
import { getSeasonsDropdownOptions } from 'api/orders'
import { Icon, ImageLoad, Select } from 'components'
import PlayerOrders from './player-orders'
import TeamOrders from './team-orders'
import classes from './orders.module.scss'

type OrderState =
  | 'list-view'
  | 'detail-view'
  | 'edit'
  | 'edit-close'
  | 'warn-empty-layer'
  | 'save'
  | 'submit'

export default function Orders() {
  const isMobile = useMediaQuery({ maxWidth: 1023 })
  const userData = sessionStorage.getItem('user')
  const user: any = userData ? JSON.parse(userData) : null

  const [orderState, setOrderState] = useState<OrderState>('list-view')
  const [loadingState, setLoadingState] = useState(0)
  const { seasons } = getSeasonsDropdownOptions()
  const filters: any = sessionStorage.getItem('filters')
  const [selectedSeason, setSelectedSeason] = useState(
    filters ? filters : '2025',
  )
  const [selectedOrder, setSelectedOrder] = useState({})

  useEffect(() => {
    ReactGA.send({
      hitType: 'pageview',
      page: window.location.pathname,
      title: 'Orders',
    })

    setTimeout(() => {
      setLoadingState(1)
    }, 800)

    setTimeout(() => {
      setLoadingState(2)
    }, 1600)
  }, [])

  if (loadingState <= 1) {
    return (
      <div className={classes.loadingContainer}>
        <div
          className={`${classes.welcomeMessage} ${
            loadingState === 1 ? classes.slideUp : ''
          }`}>
          <div className={classes.greet}>Welcome</div>
          <div className={classes.user}>{user.name}</div>
        </div>

        {loadingState === 1 && (
          <div className={classes.logoContainer}>
            <Icon name="logo" />
          </div>
        )}
      </div>
    )
  }

  const selectSeason = (option: any) => {
    setSelectedSeason(option.value)
    sessionStorage.setItem('filters', option.value)
  }

  const selectOrder = (order: any) => {
    setSelectedOrder(order)
  }

  return (
    <div
      className={`${classes.container} ${
        orderState === 'edit' ? classes.edit : ''
      }  ${orderState === 'edit-close' ? classes.editClose : ''}`}>
      <div className={classes.headerContainer}>
        <div className={classes.header}>
          <div className={classes.logoContainer}>
            <Icon name="logoSmall" />
          </div>
          <div className={classes.userContainer}>
            <div className={classes.userInfo}>
              <div className={classes.username}>{user.name}</div>
              {user.teamName && (
                <div className={classes.team}>{user.teamName}</div>
              )}
            </div>
            <div className={classes.userIcon}>
              <ImageLoad src={user.profileLogo} />
            </div>
          </div>
        </div>
      </div>

      <div className={classes.ordersMainSection}>
        <div className={classes.ordersSection}>
          <div className={classes.secondHeaderContainer}>
            <div className={`${classes.header} ${classes.secondHeader}`}>
              <div className={classes.title}>
                Pick a style to review and customize
              </div>
              <div className={classes.seasonContainer}>
                <div className={classes.seasonLabel}>Season</div>
                <div className={classes.seasonSelector}>
                  <Select
                    theme="dark"
                    size="large"
                    align={isMobile ? 'left' : 'right'}
                    options={seasons}
                    value={selectedSeason}
                    onSelect={selectSeason}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className={classes.ordersContainer}>
            <div className={classes.ordersListContainer}>
              {user.isTeam ? (
                <TeamOrders
                  user={user}
                  season={selectedSeason}
                  onSelect={selectOrder}
                />
              ) : (
                <PlayerOrders
                  user={user}
                  season={selectedSeason}
                  onSelect={selectOrder}
                />
              )}
            </div>
          </div>
        </div>
        <Outlet context={[orderState, setOrderState, selectedOrder]} />
      </div>
    </div>
  )
}
