import React from 'react'
import { ApolloProvider } from '@apollo/client'
import { client } from 'providers'
import { Routes } from 'routes'
import ReactGA from 'react-ga4'

ReactGA.initialize('G-YD0796FXXW')

function App() {
  return (
    <ApolloProvider client={client}>
      <Routes />
    </ApolloProvider>
  )
}

export default App
