import React from 'react'
import {
  Cube,
  Logo,
  LogoAnimatedWhite,
  LogoSmall,
  Palette,
  PreviewAll,
  TickLarge,
  Type,
  Warn,
} from './icons'

const iconNames = {
  cube: Cube,
  palette: Palette,
  previewAll: PreviewAll,
  tickLarge: TickLarge,
  type: Type,
  warn: Warn,

  logo: Logo,
  logoSmall: LogoSmall,
  logoAnimatedWhite: LogoAnimatedWhite,
}

interface Props {
  name: keyof typeof iconNames
}

export default function Icon({ name }: Props) {
  const IconComponent = iconNames[name]
  return <IconComponent />
}
